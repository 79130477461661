<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws">
      <h3>Продать за наличные</h3>
    </div>

    <div>
      <v-data-table
          :items="cash"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc
          show-expand

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }">  {{item.id}}  </template>
        <template v-slot:item.status="{ item }">  {{item.status}}  </template>
        <template v-slot:item.operation_type="{ item }">  {{item.operation_type}}  </template>
        <template v-slot:item.cryptocurrency_amount="{ item }">  {{item.cryptocurrency_amount}}  </template>
        <template v-slot:item.currency="{ item }">  {{item.currency.short_title}}  </template>
        <template v-slot:item.currency_amount="{ item }">  {{item.currency_amount}}  </template>
        <template v-slot:item.commission="{ item }">  {{item.commission}}  </template>
        <template v-slot:item.created_at="{ item }">  {{  new Date(Date.parse(item.created_at)).toLocaleString()  }}  </template>
        <template v-slot:item.city="{ item }">  {{item.city}}  </template>
        <template v-slot:item.address="{ item }">  {{item.address}}  </template>

        <template v-slot:item.actions="{ item }">
          <v-btn class="green"    dense text   @click="success(item)">Принять </v-btn>

        </template>


        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length-2">
            <span> {{ `ID юзера: ${item.user.id}`}} </span><br>
            <span> {{ `nickname: ${item.user? item.user.nickname:""}`}} </span><br>
            <span> {{ `Баланс до: ${item.balance_before.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'})}`}} </span><br>
            <span> {{ `Баланс после: ${item.balance_after.toLocaleString('ru-RU', {style: 'currency', currency: 'USD'})}`}} </span><br>
            <span> {{ `Контактные данные: ${ item.contact }`}} </span><br>
            <span> {{ `Забронированное время и дата: ${item.time}   ${item.date} `}} </span>
          </td>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn v-if='item.status==="В ожидании"' class="green"    dense text   @click="at_work(item)">  Взять в работу  </v-btn>
          <v-btn v-if='item.status==="В процессе"' class="green"    dense text   @click="success(item)">  Наличные получены  </v-btn>

          <NumberEditDialog v-if='item.status==="В процессе"' caption="Правка суммы" title="Правка суммы" label="Сумма" :tag="item"
                            :value="item.currency_amount" v-on:ok="amount"></NumberEditDialog>

          <TextInputDialog  buttonclass="red" buttontext="Отклонить" caption="Причина" :tag="item" v-on:ok="cancel"></TextInputDialog>
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";

import Snackbar from "../components/controls/Snackbar.vue";
import TextInputDialog from "../components/dialogs/TextInputDialog.vue";
import NumberEditDialog from "../components/dialogs/NumberEditDialog.vue";


import {fetcher, getHeaders} from "../store";


export default {
  name: 'Cash',

  components: { Snackbar, TextInputDialog, NumberEditDialog},

  apollo: {
    $subscribe: {
      cash: {
        query: gql`subscription MyQuery {
  cashes(where: {_and:[{operation_type: {_eq: "Продажа"}}, {status: { _in: ["В процессе", "В работе", "В ожидании"] }}] }   ) {
    address
    cryptocurrency_amount
    balance_after
    balance_before
    city
    code
    commission
    contact
    created_at

    currency_amount
    delivery_type
    cancellation_message

    id
    time
    date
    operation_type
    status
    time
    updated_at

    currency {
      short_title
    }

    user {
      id
      nickname
    }

  }
}



        `,
        result({data}) {
          this.cash = data.cashes;
        },
      },
    },
  },


  data: () => ({
    tag: 'test',
    cash: [],

    headers: [
      { value: "id", text: "ID заявки" },
      { value: "status", text: "Статус" },
      { value: "operation_type", text: "Операция" },
      { value: "cryptocurrency_amount", text: "Сумма ₽" },
      { value: "currency", text: "Валюта(фиат)" },
      { value: "currency_amount", text: "Сумма(фиат)" },
      { value: "commission", text: "Комиссия" },
      { value: "created_at", text: "Дата" },
      { value: "city", text: "Город" },
      { value: "address", text: "Офис получения" },

      { value: "code", text: "Код выдачи" },

      { value: "actions", text: "actions" },


/*

       { value: "user_id", text: "ID юзера" },
       { value: "nickname", text: "nickname" },
       { value: "balance_old", text: "Баланс USDT до" },
       { value: "balance_now", text: "Баланс USDT после" },
       { value: "contact", text: "Контактные данные" },*/

    ],
  }),

  methods: {

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    async at_work( value ) {
      //  /conclusion/success/{conclusion_id}':

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/cashes/${value.id}/hire/1`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async success( value ) {
      //  /conclusion/success/{conclusion_id}':

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/cashes/${value.id}/success/1`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async amount( item, value ) {
      //  /conclusion/success/{conclusion_id}':

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({    amount : value})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/cashes/${item.id}/change_amount/1`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async cancel( value , text ) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify({cancellation_message : text})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/cashes/${value.id}/1`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    amount_usdt(item){
      return item.conversions[0] ? Math.abs(item.conversions[0].balance_usdt_old - item.conversions[0].balance_usdt_now) : 0
    },

    amount_btc(item){
      return item.conversions[0] ? Math.abs(item.conversions[0].balance_btc_old - item.conversions[0].balance_btc_now) : 0
    },

    money_from(item){
      if (item.conversions){
        if (item.conversions[0].convert_to==="USDT")   //from btc
          return this.amount_btc(item) + " BTC"
        else
          return this.amount_usdt(item) + " USDT"
      }else
        return "0"
    },

    money_to(item){
      if (item.conversions){
        if (item.conversions[0].convert_to==="USDT") //from usdt
          return this.amount_usdt(item) + " USDT"
        else
          return this.amount_btc(item) + " BTC"
      }else
        return "0"
    },

    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

  },

}
</script>
